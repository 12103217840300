import React, {useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {CheckoutContext} from "./CheckoutContext";
import {usePurchase} from "../../controllers/usePurchase";
import {useHotel} from "../../controllers/useHotel";
import {validate} from "../../helpers/validate";
import dayjs from "dayjs";
import superjson from "superjson";
import {useLocalStorage} from "@mantine/hooks";
import {
  CheckRatesResult,
  Hotel,
  HotelForPurchase,
  HotelForPurchaseRoom,
  KnownHotelErrors
} from "../../types/hotels/hotels";
import {CheckoutError, PaymentData, PaymentPersons} from "../../types/payment";
import {Police} from "../../types/insurance";
import {
  GeneralUtilProps,
  manageCheckoutUnknownError,
  manageHotelErrorInsufficientAllotment
} from "./checkoutHotelUtils";
import {CreatePaymentIntentProps} from "../../controllers/usePurchase/usePurchase.types";
import {getTotalClientTaxesFromRate} from "../../components/Hotels/HotelDetails/util";
import {UseHotelTypes} from "../../controllers/useHotel/models";


export interface CheckoutPerson {
  type: 'adult' | 'child',
  age?: number
}


interface CheckoutProviderProps {
  children: React.ReactNode
}

const CheckoutProvider = (props: CheckoutProviderProps) => {
  const {updatePaymentIntent} = usePurchase();
  const {checkRates: checkHotelRatesController, getTypes} = useHotel();
  const form = useForm();


  const [hotels, setHotels] = useLocalStorage<HotelForPurchase[]>({
    key: 'purchaseHotels',
    defaultValue: [],
    serialize: superjson.stringify,
    deserialize: (str) => (str === undefined ? [] : superjson.parse(str))
  });

  const [activities, setActivities] = useState([]);

  // const [activities, setActivities] = useLocalStorage({
  //   key: 'purchaseActivities',
  //   defaultValue: [],
  //   serialize: superjson.stringify,
  //   deserialize: (str) => (str === undefined ? [] : superjson.parse(str))
  // });


  const [persons, setPersons] = useLocalStorage<CheckoutPerson[]>({
    key: 'purchasePersons',
    defaultValue: [],
    serialize: superjson.stringify,
    deserialize: (str) => (str === undefined ? [] : superjson.parse(str))
  });

  const [selectedInsurance, setSelectedInsurance] = useState<Police | null>(null);

  const [errors, setErrors] = useState({});

  const {getValues} = form;


  const clearActivities = () => setActivities([]);
  const clearHotels = () => setHotels([]);
  const clearPersons = () => setPersons([]);

  const changeAdults = (action: 'add' | 'remove' = 'add', n: number) => setPersons(prev => {
    let persons = [...prev];
    if (action === 'add') {
      for (let i = 0; i < n; i++) persons.push({type: 'adult'});
    } else {
      for (let i = 0; i < n; i++) {
        let index = persons.findIndex(p => p.type === 'adult');
        if (index !== -1) persons.splice(index, 1);
      }
    }
    return persons;
  });

  // action === add && ages = array
  // action === remove && ages = array | number
  // const changeChildren = (action = 'add', ages: number[] | number) => setPersons(prev => {
  //   let persons = [...prev];
  //   if (action === 'add' && Array.isArray(ages)) {
  //     ages.forEach(age => persons.push({type: 'child', age}));
  //   } else {
  //     if (Array.isArray(ages)) {
  //       ages.forEach(age => {
  //         let i = persons.findIndex(p => p.age === age);
  //         if (i !== -1) persons.splice(i, 1);
  //       });
  //     } else {
  //       let i = persons.findIndex(p => p.type === 'child');
  //       if (i !== -1) persons.splice(i, 1);
  //     }
  //   }
  //   return persons;
  // });


  const changeChildren = (action = 'add', n: number[] | number) => setPersons(prev => {
    let persons = [...prev];
    if (action === 'add') {
      for (let i = 0; i < n; i++) persons.push({type: 'child', age: 1});
    } else {
      for (let i = 0; i < n; i++) {
        let index = persons.findIndex(p => p.type === 'child');
        if (index !== -1) persons.splice(index, 1);
      }
    }
    return persons;
  });


  //ISO2
  const findAllCountryCodes = () => {
    let codes = new Set<string>();
    hotels.forEach(hotel => codes.add(hotel.countryCode));
    return [...codes].filter(a => a);
  }

  // only holder
  const findAllResidentsCountryCodes = (): string[] => {
    const data = getCleanFormData();
    return [data?.persons?.find((person: any) => person?.holder)?.nationality].filter(a => a)

    // const codes = new Set<string>();
    // data?.persons?.forEach((person: any) => codes.add(person.nationality));
    //
    // const result = [...codes];
    // return result.filter(a => a)
  }

  const getFinalDates = () => {
    let from = dayjs().add(20, 'year');
    let to = dayjs().subtract(20, 'year');

    if (Array.isArray(hotels)) {
      hotels.forEach(hotel => {
        hotel.rooms.forEach(room => {
          if (dayjs(room.rate.from).isBefore(from)) from = dayjs(room.rate.from);
          if (dayjs(room.rate.to).isAfter(to)) to = dayjs(room.rate.to);
        });
      });
    }

    /*		if (Array.isArray(activities)) {
          activities.forEach(activity => {
            activity.selectedRates.forEach(rate => {
              if (dayjs(rate.from).isBefore(from)) from = dayjs(rate.from);
              if (dayjs(rate.to).isAfter(to)) to = dayjs(rate.to);
            });
          });
        }*/
    return {from, to};
  }


  // valida los datos
  const checkPayingInformation = (data: any): string | undefined => {
    const persons = data.persons;

    if (!Array.isArray(persons) || persons.length === 0) return 'invalid form.persons.missing';

    // check number of adults and children
    let paxes: any = {children: 0, adults: 0}

    data.persons.forEach((person: any) => {
      if (dayjs().diff(person.birthday, 'year') < 18) paxes.children++;
      else paxes.adults++;
    });


    // check email
    if (!validate.email(data.email)) return 'invalid form.email';

    let error = (validate.checkout.persons(persons) || validate.checkout.emergencyContact(data.emergency_contact) || validate.checkout.billing(data.billing)) as string | undefined;
    if (error) return error

    // if (Array.isArray(hotels)) {
    //   let expected_pax = {children: 0, adults: 0};
    //   hotels.forEach(hotel => {
    //     hotel.rooms.forEach(room => {
    //       expected_pax.adults += room.rate.adults * room.rate.rooms;
    //       expected_pax.children += room.rate.children * room.rate.rooms;
    //     });
    //   });
    //   if (expected_pax.adults !== paxes.adults || expected_pax.children !== paxes.children) return 'invalid form.persons.missing';
    // }

    return undefined
  }

  const clearPurchaseData = () => {
    clearHotels();
    clearActivities();
    clearPersons();
  }


  // --- HOTELS ---
  const checkHotelRates = async (): Promise<{ hotels: HotelForPurchase[], ratesChanged: boolean }> => {
    let roomsToCheck: HotelForPurchaseRoom[] = [];
    const types = await getTypes()
    if (Array.isArray(hotels)) {
      hotels.forEach(hotel => {
        if (Array.isArray(hotel.rooms)) {
          hotel.rooms.forEach(room => {
            if (room.rate.rateType === 'RECHECK') roomsToCheck.push(room);
          });
        }
      });
    }

    // replace rates with new ones
    if (roomsToCheck.length > 0) {
      const {error, data} = await checkHotelRatesController({rooms: roomsToCheck});
      if (error) {
        throw error;
      }
      // we have rates to update
      if (Array.isArray(data) && data.length > 0) {
        return {hotels: replaceHotelRates(data, types!), ratesChanged: true};
      }
      return {hotels, ratesChanged: false};
    }

    return {hotels, ratesChanged: false};
  }

  const replaceHotelRates = (newRates: CheckRatesResult[], types: UseHotelTypes): HotelForPurchase[] => {
    let nHotels = [...hotels].map(hotel => {
      let new_hotel = newRates.find(h => h.code === hotel.code && h.api === hotel.api);
      if (!new_hotel) return hotel;

      let new_rooms = hotel.rooms.map(room => {
        let new_room = new_hotel?.rooms?.find(r => r.code === room.code);
        if (!new_room) return room;

        let new_rate = new_room.rates.find(r => r.rateKey === room.rate.rateKey);
        if (!new_rate) return room;

        return {
          ...room,
          rate: {
            rateKey: new_rate.rateKey,
            rateType: new_rate.rateType,
            from: room.rate.from,
            to: room.rate.to,
            net: new_rate.net,
            finalPrice: Number.parseFloat(new_rate.net) * types.commission,
            allotment: new_rate.allotment,
            adults: room.rate.adults,
            children: room.rate.children,
            rooms: room.rate.rooms,
            taxes: getTotalClientTaxesFromRate(new_rate)
          }
        };
      });

      return {
        ...hotel,
        rooms: new_rooms
      }
    });
    setHotels(nHotels);
    return nHotels;
  }

  const addNewRoomsToReserve = (hotel: Hotel | HotelForPurchase, rooms: HotelForPurchaseRoom[]) => {
    setHotels([{
      ...hotel,
      rooms
    }])

    // setHotels((prevHotels: HotelForPurchase[]) => {
    //   let h_found = false;
    //   let fHotels = prevHotels.map((h: HotelForPurchase) => {
    //     if (h.code === hotel!.code && h.api === hotel!.api) {
    //       h_found = true;
    //       return {...h, rooms: [...h.rooms, ...rooms]};
    //     }
    //     return h;
    //   });
    //   if (!h_found) {
    //     fHotels.push({
    //       ...hotel!,
    //       rooms
    //     });
    //   }
    //   return fHotels;
    // });
  }


  const createPropsForCreatePaymentIntent = () => {
    let result: CreatePaymentIntentProps = {
      products: {},
      destinationsISO2Codes: createDestinationsISO2Codes()
    };

    if (Array.isArray(hotels)) {
      result.products.hotels = hotels.map(hotel => ({
        code: hotel.code,
        api: hotel.api,
        rooms: hotel.rooms.map(room => ({
          rate: {
            rateKey: room.rate.rateKey,
            adults: room.rate.adults,
            children: room.rate.children,
            rooms: room.rate.rooms,
            checkIn: dayjs(room.rate.from).format('YYYY-MM-DD'),
            checkOut: dayjs(room.rate.to).format('YYYY-MM-DD')
          }
        }))
      }));
    }

    // here will be the activities

    return result;
  }


  const getCleanFormData = () => {
    let data = getValues(); // form data

    if (Array.isArray(data.persons)) {
      data.persons = data.persons.slice(0, persons.length).map((person: PaymentPersons) => {
        if (person.birthday) {
          person.age = dayjs().diff(person.birthday, 'year');
          //person.children = person.age < 18;
        }
        return person;
      });
      if (!selectedInsurance) {
        data.persons = [data.persons[0]]
      }
    }

    if (!data.billing?.want) {
      data.billing = undefined;
    } else {
      delete data.billing?.want
    }

    return data;
  }

  const createDestinationsISO2Codes = () => {
    let destinations: string[] = [];
    if (Array.isArray(hotels)) {
      hotels.forEach(hotel => {
        if (!destinations.includes(hotel.countryCode)) destinations.push(hotel.countryCode);
      });
    }
    return destinations;
  }

  // Todo: rename to createPaymentData
  const createPurchaseData = ({data, hotels}: { data: { [p: string]: any }, hotels: HotelForPurchase[] }) => {

    let paymentData: PaymentData = {
      email: data.email,
      persons: data.persons,
      //billing: data.billing,
      emergency_contact: data.emergency_contact,
      destinationsISO2Codes: createDestinationsISO2Codes()
    }
    if (data.billing?.want) {
      paymentData.billing = data.billing;
    }

    if (selectedInsurance) {
      paymentData.policyId = selectedInsurance!.id;
    }


    let paymentIntentProducts = createPropsForCreatePaymentIntent();
    if (paymentIntentProducts.products.hotels) paymentData.hotels = paymentIntentProducts.products.hotels;

    return paymentData;
  }

  // throws
  const preparePayment = async (payment_id: string, order_id: string) => {
    let data = getCleanFormData();
    let checkResultError = checkPayingInformation(data);
    if (checkResultError) throw new Error(checkResultError);
    const hotelTypes = await getTypes()

    const {hotels, ratesChanged} = await checkHotelRates(); // can throw error

    /*    if (ratesChanged) {
          alert('rates changed');
        }*/

    let paymentData = createPurchaseData({data, hotels});

    const {hotelUpdatedRates, success} = await updatePaymentIntent({
      order: paymentData,
      payment_id: payment_id!,
      order_id: order_id!
    });

    if (hotelUpdatedRates.length > 0) {
      const updatedHotels = replaceHotelRates(hotelUpdatedRates, hotelTypes!);
      paymentData = createPurchaseData({data, hotels: updatedHotels});

      const {success} = await updatePaymentIntent({
        order: paymentData,
        payment_id: payment_id!,
        order_id: order_id!
      });
      if (!success) {
        window.location.reload();
      }
    } else if (!success) {
      window.location.reload();
    }

    return {paymentData, ratesChanged};
  }

  function manageError(error: CheckoutError) {
    switch (error.message) {
      case KnownHotelErrors.insufficientAllotment:
        manageHotelErrorInsufficientAllotment(error, generateUtilProps)
        break
      default:
        manageCheckoutUnknownError(error, generateUtilProps)
        return false // error not managed
    }
    return true // error managed correctly
  }


  const generateUtilProps: GeneralUtilProps = {
    setHotels,
    setPersons
  }


  const isShoppingCartEmpty = useMemo(() => {
    return hotels.length === 0 && activities.length === 0;
  }, [hotels, activities]);


  return <CheckoutContext.Provider value={{
    form,
    hotels, setHotels,
    activities, setActivities,
    selectedInsurance, setSelectedInsurance,
    persons, setPersons, changeAdults, changeChildren,
    errors, setErrors,
    checkPayingInformation,
    createPurchaseData,
    preparePayment, clearPurchaseData,
    findAllCountryCodes, getFinalDates, findAllResidentsCountryCodes,
    createPropsForCreatePaymentIntent,
    replaceHotelRates, getCleanFormData,
    addNewRoomsToReserve,
    isShoppingCartEmpty,
    manageError
  }}>
    <FormProvider {...form}>
      {props.children}
    </FormProvider>
  </CheckoutContext.Provider>

}

export default CheckoutProvider;
